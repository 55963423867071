<hr class="m-0">

<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="education">
  <div class="w-100">
    <h2 class="mb-5">Education</h2>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="resume-content">
        <h3 class="mb-0">University of Southern Denmark</h3>
        <div class="subheading mb-3">Master's Programme (MSc) in Engineering, Learning and Experience Technology</div>
        <div>Odense, Denmark</div>
        <p><b>Topics:</b> Human Robotic Interaction; Estetics, creativity, and behaviourism; Social media and learning
          processes; Programming of robots and other physical devices; 3D modelling with Maya; Game development with
          Unity (C#)</p>
        <p><b>Master Thesis - <em>"Designing a digital learning object to use in sex education"</em>:</b> The
          development of two learning platforms for the elementary school. One for web and one for mobile devices. Both
          teachers and students were involved in the development process. The project was supported by various learning
          and technology theories.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">September 2015 - June 2017</span>
      </div>
    </div>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
      <div class="resume-content">
        <h3 class="mb-0">University of Southern Denmark</h3>
        <div class="subheading mb-3">Bachelor of Science (BSc) in Engineering, Learning and Experience Technology</div>
        <div>Odense, Denmark</div>
        <p><b>Topics:</b> Game theory and game development with Gamemaker; Learning theories and developing learning
          objects; Design and interaction (HCI); Hardware and Robot technology (Lego Mindstorms and Arduino); Web
          programming; Project Management</p>
        <p><b>Bachelor Project - <em>"User interface for 3D printer"</em>:</b> The development of a user interface for
          building 3D figures with the purpose of allowing users, no matter their skills, create and print their own 3D
          figure. Besides web development a large part of the project was to examine 3D modelling and 3D printing in
          order to being able to create the 3D figures for printing.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">September 2012 - June 2015</span>
      </div>
    </div>

  </div>
</section>