<div class="container-fluid p-0">

  <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="about">
    <div class="w-100">
      <h1 class="mb-0 myName">{{person.firstname}}
        <span class="text-primary myName">{{person.lastname}}</span>
      </h1>
      <div class="subheading mb-5">{{person.address}} ·
        <a href="mailto:{{person.email}}">{{person.email}}</a>
      </div>
      <p class="lead mb-5">My interests are found in the interplay between human and technology. Through my education I have learned to combine engineering science in software and hardware with learning and user experiences. I have learned how to use technology with the focus on play, learning and interaction.</p>
      <p class="lead mb-5">As a person I am very curious as to how technology and people work. I am at my best when I am with people and i get to work and develop which is why I chose the education as an engineer with interest in both people and the technology that surrounds us.</p>
      <div class="social-icons">
        <a target="_blank" href="{{person.linkedIn}}">
          <i class="fab fa-linkedin-in"></i>
        </a>
        <!-- <a href="#">
            <i class="fab fa-github"></i>
          </a> -->
        <!-- <a href="#">
            <i class="fas fa-at"></i>
          </a> -->
      </div>
    </div>
  </section>