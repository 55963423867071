import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  person = {
    firstname : "Didde Marie",
    lastname : "Hellestrup",
    address : "5250 Odense SV",
    birthday : "22. juli 1990",
    email : "frk.hellestrup@gmail.com",
    linkedIn : "https://www.linkedin.com/in/diddehellestrup/"
  }

  constructor() { }

  ngOnInit(): void {
  }

}
