<hr class="m-0">

<section class="resume-section p-3 p-lg-5 d-flex" id="projects">
    <div class="w-100">
      <h2 class="mb-5">Projects</h2>

      <div class="container">

          <div class="row">

              <div class="col-md-6 col-xl-4">
                <div class="card h-100">
                    <img src="../../../assets/img/maui-diddehellestrup2.png" alt="" class="card-img-top">
                    <div class="card-body d-flex flex-column">
                
                        <h5 class="card-title">Random Images</h5>
                        <p class="card-text">Subsite which displays random images of my cat with Javascript.</p>
                      
                        <button type="button" class="btn btn-light mt-auto" id="maui-diddehellestrup" onclick="window.open('https://maui.diddehellestrup.dk')"><i class="fas fa-external-link-alt"></i> Open site</button>
                    </div>
                </div>
              </div>

              <div class="col-md-6 col-xl-4">
                <div class="card h-100">
                    <img src="../../../assets/img/prusa_diddehellestrup.png" alt="" class="card-img-top">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title">Prusa i3 MK3S+ self-assembly</h5>
                        <p class="card-text text">In november 2020 I bought a 3D printer kit from Prusa and build it in aproximately 10 hours.</p>
                        <button type="button" class="btn btn-light mt-auto video-btn" data-toggle="modal" data-src="https://www.youtube.com/embed/WCDPgsEmUvk" data-target="#youtubeModal" id="maui-diddehellestrup">Watch timelapse</button>
                    </div>
                </div>
              </div>
            
          </div>
      </div>  
    </div>

    <!-- Youtube Modal -->
    <div class="modal fade" id="youtubeModal" tabindex="-1" role="dialog" aria-labelledby="youtubeModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="embed-responsive embed-responsive-16by9">
              <iframe class="embed-responsive-item" src="" id="video" allow="autoplay"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
