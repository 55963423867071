<hr class="m-0">

<section class="resume-section p-3 p-lg-5 d-flex justify-content-center" id="experience">
  <div class="w-100">
    <h2 class="mb-5">Experience</h2>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="resume-content">
        <h3 class="mb-0">Team Coordinator, Social and Health Applications</h3>
        <div class="subheading mb-3">Odense Municipality - Odense, Denmark</div>
        <p>As a team coordinator for seven fullstack developers who develops the EOJ-System for Odense Municipality, I
          am a part of defining, describing, and coordinating the tasks in relation to the time schedule involving
          implementation of modules from third parties, developing functionality and phasing out legacy systems.</p>
        <p>I am a part of the full process from the clarification of user needs to the clarification of architecture and
          development in a close collaboration with business consultants, it-architects and developers.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">August 2017 - present</span>
      </div>
    </div>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="resume-content">
        <h3 class="mb-0">Usability Consultant, Project Employment</h3>
        <div class="subheading mb-3">MV-Nordic - Odense, Denmark</div>
        <p>As a Usability Consultant at MV-Nordic I was hired for a time limited position. My assignment was to asses
          the usability in MV-Nordics own read and write products, and to compare it to apps and systems by competitors.
          The end goal was a report and a presentation to members of the management, regarding my suggestions to were
          MV-Nordic could benefit making some changes to their products.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">July 2017 - August 2017</span>
      </div>
    </div>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="resume-content">
        <h3 class="mb-0">Student Assistant, Camp12</h3>
        <div class="subheading mb-3">MV-Nordic - Odense, Denmark</div>
        <p>As a student assistant at MV-Nordic I was a part of assessing products from third parties, user surveys, data
          collection, data processing, and knowledge sharing. The workflow was very analytic and we were in large part
          co-deciders as to how the surveys were implemented in order for achieving the best results.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">February 2017 - June 2017</span>
      </div>
    </div>

    <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
      <div class="resume-content">
        <h3 class="mb-0">Instructor in Interaction Design</h3>
        <div class="subheading mb-3">University of Southern Denmark, The Faculty of Engineering - Odense, Denmark</div>
        <p>My work consisted of teaching, guidance, and correcting assignments for first semester bachelor students from
          Software og IT.</p>
      </div>
      <div class="resume-date text-md-right">
        <span class="text-primary">September 2015 - February 2016</span>
      </div>
    </div>

  </div>

</section>